// Disable eslint until we add more to this file.
// eslint-disable-next-line import/prefer-default-export
export const colors = {
  darkBlue: '#2C4368',
  darkNavy: '#001E65',
  hoverBlue: '#305ac2',
  detailTextBlue: '#345581',
  headerTextBlue: '#0A1C42',
  lightBlue: '#CAEAF5',
  lighterBlue: '#E1F4F6',
  orange: '#E35224',
  hoverOrange: '#FF3D00',
  lightOrange: '#fcefeb',
  tan: '#F6F3E1',
  hoverTan: '#FFF9D2',
  navyBlue: '#0F1B39',
  lightGray: '#F3F3F3',
  white: '#FFFFFF',
  midGray: '#989898',
  gray: '#636363',
  darkGray: '#343434',
  black: '#000000',
  transparent: 'rgba(0, 0, 0, 0)',
}
